.analytics-wrapper {
    text-align: center;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.analytics-header {
    color: #ffffff;
    background-image: linear-gradient(270deg, #0063A4 0.23%, #4EBFA4 100%);
    height: 125px;
    min-height: 125px;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 0;
}

.total-alert {
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: -0.45px;
    margin: 0 0 0 0;
    padding: 17px 0 0 0;
}

.total-amount {
    font-style: normal;
    font-size: 35px;
    line-height: 15px;
    letter-spacing: -0.45px;
    margin: 15px 0 22px 0;
}

.blocked-amount {
    font-style: normal;
    font-size: 17px;
    letter-spacing: -0.45px;
    margin: 0 0 0 0;
    color: #95E2E3;
}

.analytics-main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.analytics-row-item {
    font-style: normal;
    font-size: 19px;
    box-sizing: border-box;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 25px;
    border-bottom: 1px solid #8E8E8E;
}

.analytics-row-item-expand {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    box-sizing: border-box;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #8E8E8E;
}

.analytics-between {
    position: sticky;
    min-height: 30px;
    height: 30px;
    width: 100%;
    background-image: linear-gradient(90deg, #5BC1A3 0%, #00B5AD 29%, #005BA3 100%);
}