.background-container-date {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 249px;
    width: 14%;
    height: 300px;
    min-height: 26%;
    margin: 56px auto;
    background: #ffffff;
    box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
}

.MuiDialog-root {
    bottom: auto;
}

.date-picker-wrapper {
    text-align: center;
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.date-picker-item {
    display: grid;
    width: 300px;
    max-width: 100%;
    margin: 10px 10px 10px 10px;
}

.date-picker-date {
    display: flex;
    width: 300px;
    max-width: 100%;
    margin: 10px 10px 10px 10px;
}